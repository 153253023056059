<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\popup\modal-page-select-agent.vue
  FILE_NAME : modal-page-select-agent
  AUTHOR : bykim
  DATE : 2024-03-19
  DESCRIPTION : 근무스케줄 등록 상담원 선택 페이지
-->
<template>
  <div class="container">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @row-click="onRowClick" @cell-hover-changed="onCellHoverChanged" />
    <div class="page-sub-box mt-3">
      <div class="bottom-btn-wrap text-center">
        <DxButton
          text="이 전"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="onPagePrev"
        />
        <DxButton
          text="다 음"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="onPageNext"
        />
        <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onPageClose" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/plugins/common-lib';
  import ArrayStore from 'devextreme/data/array_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxButton },
    props: {
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-select-agent',
          nextPageName: 'modal-page-worktype',
          prevPageName: 'modal-page-initial-settings',
          agentData: {},
          workCategory: {},
          submitList: [],
        }),
      },
    },
    data() {
      return {
        dataGrid: {
          refName: 'agentWorkScheduleGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          height: '490', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
            cellHoverChanged: true, // 셀 호버 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '대상자 삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 100,
                  height: 30,
                  onClick: this.onDeleteData,
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'row', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
            refreshMode: 'full', // 편집 후 그리드 리프레시 모드 : ['full', 'reshape', 'repaint']
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: true, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptNmPath',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '근무일자',
              dataField: 'workDt',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
            },
            {
              caption: '일반근무시간',
              dataField: 'workBasicTime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.mergeWorkBasicTime,
            },
            {
              caption: '시간외근무시간',
              dataField: 'workOvertime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: this.mergeWorkOvertime,
            },
          ], // 컬럼 정보
        },
        isSelectionStopped: true,
      };
    },
    computed: {},
    methods: {
      onPageNext() {
        this.appointmentData.nextPageName = 'modal-page-worktype';
        this.appointmentData.prevPageName = 'modal-page-select-agent';
        this.appointmentData.submitList = [];
        let results = [];
        let rows = this.dataGrid.dataSource._array;

        if (rows.length === 0) {
          this.$_Toast('근무자가 없습니다.');
          return;
        }

        rows.forEach(row => {
          let found = results.find(r => r.agtid === row.agtid);
          if (!found) {
            results.push({ agtid: row.agtid, workDateList: [row.workDt.replace(/-/g, '')], workTypeId: '' });
          } else {
            found.workDateList.push(row.workDt.replace(/-/g, ''));
          }
        });
        this.appointmentData.submitList = results;
        this.$emit('pageNext', this.appointmentData);
      },
      onPagePrev() {
        this.appointmentData.prevPageName = 'modal-page-initial-settings';
        this.appointmentData.deptCdList = [];
        this.appointmentData.agtIdList = [];
        this.$emit('pagePrev', this.appointmentData);
      },
      onPageClose() {
        this.$emit('pageClose');
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} [${rowData.agtid}]`;
      },
      /**
       * @description : 일반근무 시간 병합
       * @param rowData
       * @return {string}
       */
      mergeWorkBasicTime(rowData) {
        if (rowData === null || rowData === undefined || rowData.workBasicStartTime === null || rowData.workBasicEndTime === null) {
          return '';
        }
        return `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
      },
      /**
       * @description : 시간외근무 시간 병합
       * @param rowData
       * @return {string}
       */
      mergeWorkOvertime(rowData) {
        if (rowData === null || rowData === undefined || rowData.workOverTimeStartTime === null || rowData.workOverTimeEndTime === null) {
          return '';
        }
        return `${rowData.workOverTimeStartTime} ~ ${rowData.workOverTimeEndTime}`;
      },
      /**
       * @description : 상담원 조회
       */
      async onSearch() {
        let vm = this;
        const payload = {
          actionname: 'EWM_WORK_SCHEDULE_AGENT_LIST',
          data: {
            betweenWorkDt: `${vm.appointmentData.startDate}~${vm.appointmentData.endDate}`,
            deptCdList: vm.appointmentData.deptCdList === undefined ? [] : vm.appointmentData.deptCdList,
            agtIdList: vm.appointmentData.agtIdList,
          },
          loading: false,
        };

        const dataArr = await vm.CALL_EWM_API(payload).then(res => {
          if (isSuccess(res)) {
            return res.data.data;
          }
          return [];
        });
        this.dataGrid.dataSource = new ArrayStore({
          key: 'id',
          data: dataArr,
        });
      },
      /**
       * @description : 그리드 행 클릭 이벤트
       */
      onRowClick(e) {
        let keys = e.component.getSelectedRowKeys();
        let index = keys.indexOf(e.key);

        if (index > -1) {
          keys.splice(index, 1);
        } else {
          keys.push(e.key);
        }
        e.component.selectRows(keys);
      },
      /** @description : 상담사 셀 호버 이벤트  */
      onCellHoverChanged(e) {
        const event = e.event;
        if (event.buttons === 1) {
          if (this.isSelectionStopped) {
            this.isSelectionStopped = false;
            this.selectedRange = {};
          }

          if (this.selectedRange.startRowIndex === undefined) {
            this.selectedRange.startRowIndex = e.rowIndex;
          }

          if (!this.selectedRange.startColumnIndex) {
            this.selectedRange.startColumnIndex = e.columnIndex;
          }

          this.selectedRange.endRowIndex = e.rowIndex;
          this.selectedRange.endColumnIndex = e.columnIndex;

          let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
          let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

          let indexes = [];
          for (let i = start; i <= end; i++) {
            indexes.push(i);
          }
          e.component.selectRowsByIndexes(indexes);
        } else {
          this.isSelectionStopped = true;
        }
      },
      /**
       * @description : 근무대상자 삭제
       */
      onDeleteData() {
        let vm = this;
        let selectedRowKeys = vm.$refs.agentWorkScheduleGrid.getGridInstance.getSelectedRowKeys();
        if (selectedRowKeys.length === 0) {
          this.$_Toast('삭제할 대상을 선택해주세요.');
          return;
        }
        this.$_Confirm('근무대상자를 삭제 하시겠습니까?').then(response => {
          if (response) {
            selectedRowKeys.forEach(rowKey => {
              vm.dataGrid.dataSource._array.map((item, index) => {
                if (item.id === rowKey) {
                  vm.dataGrid.dataSource._array.splice(index, 1);
                }
              });
            });
            vm.$refs.agentWorkScheduleGrid.refreshData();
            vm.$refs.agentWorkScheduleGrid.clearFilter();
            vm.$refs.agentWorkScheduleGrid.clearSelection();
          }
        });
      },
    },
    created() {},
    mounted() {
      this.onSearch();
    },
  };
</script>

<style lang="scss" scoped></style>
